export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 6
    }
  },
  form1: {
    nama_dokter: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nama Dokter Konsulen'
    },
    perawat_pendamping: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Perawat Pendamping'
    },
    diagnosa: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Nama Tindakan'
    },

    nama_tindakan: {
      widget: 'wradio',
      data: [
        { text: 'Pasang CVP', value: 'Pasang CVP' },
        { text: 'Long Line', value: 'Long Line' },
        { text: 'Intubasi', value: 'Intubasi' },
        { text: 'Gastroscopy', value: 'Gastroscopy' },
        { text: 'Insersi Femoral/CDL', value: 'Insersi Femoral/CDL' },
        { text: 'Punctie Pluera', value: 'Punctie Pluera' },
        { text: 'Needle Cricothyroidotomy', value: 'Needle Cricothyroidotomy' },
        { text: 'Colonoscopy', value: 'Colonoscopy' },
        { text: 'Vena Section', value: 'Vena Section' },
        { text: 'Punctie Asites', value: 'Punctie Asites' },
        { text: 'Needle Thoracostomy', value: 'Needle Thoracostomy' },
        { text: 'Invasif Radiologi', value: 'Invasif Radiologi' },
        { text: 'Umbilikal', value: 'Umbilikal' },
        { text: 'WSD', value: 'WSD' },
        { text: 'Tracheostomy', value: 'Tracheostomy' },
        { text: 'lainnya', value: null, field: 'auto' }
      ],
      col: 12,
      label: 'Jenis Tindakan'
    },
    jaringan: {
      widget: 'wradio',
      data: [
        { text: 'Eksisi', value: 'Eksisi' },
        { text: 'Cairan', value: 'Cairan' },
        { text: 'Insisi', value: 'Insisi' },
        { text: 'Biopsi', value: 'Biopsi' }
      ],
      col: 6,
      label: 'Jaringan diperoleh dengan'
    },
    dikirim: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Dikirim untuk pemeriksaan Patologi'
    },
    tanggal_tindakan: {
      widget: 'wdatenormal',
      data: null,
      col: 3,
      label: 'Tanggal Tindakan'
    },
    jam_dimulai: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Jam Dimulai'
    },
    jam_selesai: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Jam Selesai'
    },
    lama_tindakan: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Lama Tindakan'
    },
    laporan_tindakan: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Laporan Tindakan'
    }
  }
}
